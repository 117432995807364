import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import styles from "../css/about-css-modules.module.css"
import Banner from "../components/Banner"
import Layout from "../components/layout/Layout"
import CustomCarousel from "../components/Carousel"
import Icon from "../components/Icon"
import Timeline from "../components/Timeline"
import { ClientCard, AwardCard } from "../components/Card"
import { 
    Row, 
    ResponsiveRow, 
    Col, 
    SectionWrapper, 
    SectionWrapperGray,
    SectionInnerWrapper, 
    CommonContentWrapper, 
    CommonContentDivider, 
    SectionHeader, 
    SectionSubHeader 
} from "../components/Wrapper"

const AboutPage = ({ data }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    let awardsBody, clientsBody, awardsList, companyBody = {};
    data.allStrapiPage.edges.map((x) => {
        if (x.node.name === "awards") awardsBody = x.node.body;
        if (x.node.name === "awardsList") awardsList = x.node.body;
        if (x.node.name === "clients") clientsBody = x.node.body;
        if (x.node.name === "theCompany") companyBody = x.node.body;
    });

    return (
        <Layout>
            <SEO
                title={`All Ins Agency - About`}
                description={`All Ins Agency`}
            />
            <Banner heading={data.allStrapiBanner.edges[0].node.heading} subHeading={data.allStrapiBanner.edges[0].node.subheading} trail={data.allStrapiBanner.edges[0].node.name} trailLink="/about" image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl} />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <ResponsiveRow alignTop={true}>
                        <Col className={styles.companyWrapper}>
                            <SectionSubHeader>HISTORY</SectionSubHeader>
                            <SectionHeader>THE COMPANY</SectionHeader>
                            <CommonContentWrapper>
                                <div
                                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(companyBody) }}
                                />
                            </CommonContentWrapper>
                        </Col>
                        <Col className={styles.missionWrapper}>
                            <SectionSubHeader>WE ARE GOOD</SectionSubHeader>
                            <SectionHeader>OUR MISSION</SectionHeader>
                            <CommonContentWrapper>
                                {data.allStrapiMission.edges.map((x, i) => (
                                    <Row key={i} alignTop={true}>
                                        <div className={styles.missionIcon}>
                                            <Icon type={x.node.icontype} name={x.node.iconname} />
                                        </div>
                                        <Col>
                                            <h3 className={styles.missionHeader}>{x.node.title}</h3>
                                            <p className={styles.missionContentWrapper}>{x.node.description}</p>
                                        </Col>
                                    </Row>
                                ))}
                            </CommonContentWrapper>
                        </Col>
                    </ResponsiveRow>
                </SectionInnerWrapper>
            </SectionWrapper>
            <SectionWrapperGray>
                <SectionInnerWrapper>
                    <Timeline data={data.allStrapiTimeline.edges} />
                </SectionInnerWrapper>
            </SectionWrapperGray>
            <SectionWrapper>
                <SectionInnerWrapper>
                    <SectionSubHeader>THE BEST</SectionSubHeader>
                    <SectionHeader>AWARDS</SectionHeader>
                    <CommonContentWrapper>
                        <div
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(awardsBody) }}
                        />
                    </CommonContentWrapper>
                    <CommonContentDivider />
                    <CustomCarousel items={data.allStrapiAward.edges} showDots={false} numOfItemsToShow={[4, 4, 2]}>
                        {data.allStrapiAward.edges.map((x, i) => (
                            <AwardCard key={i} logoUrl={data.site.siteMetadata.apiUrl + x.node.fields.imageUrl} />
                        ))}
                    </CustomCarousel>
                    <CommonContentWrapper>
                        <div className={styles.awardsListWrapper}
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(awardsList) }}
                        />
                    </CommonContentWrapper>
                </SectionInnerWrapper>
            </SectionWrapper>
            {/* <SectionWrapperGray>
                <SectionInnerWrapper>
                    <SectionSubHeader>THEY TRUST US</SectionSubHeader>
                    <SectionHeader>CLIENTS</SectionHeader>
                    <CommonContentWrapper>
                        <div
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(clientsBody) }}
                        />
                    </CommonContentWrapper>
                    <CommonContentDivider></CommonContentDivider>
                    <CustomCarousel items={data.allStrapiClient.edges} showDots={false} numOfItemsToShow={[6, 4, 3]} containerClass={styles.carouselContainer}>
                        {data.allStrapiClient.edges.map((x, i) => (
                            <ClientCard key={i} logoUrl={data.site.siteMetadata.apiUrl + x.node.fields.logoUrl} />
                        ))}
                    </CustomCarousel>
                </SectionInnerWrapper>
            </SectionWrapperGray> */}
        </Layout >
    )
}

export default AboutPage

export const aboutQuery = graphql`
    query AboutQuery {
        allStrapiBanner(filter: {page: {eq: "/about"}}, sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiMission(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    title
                    description
                    icontype
                    iconname
                }
            }
        }
        allStrapiAward(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiClient(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    fields {
                        logoUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiPage {
            edges {
                node {
                    name
                    body
                }
            }
        }
        allStrapiTimeline(sort: {fields: fields___sortindexnum}) {
            edges {
                node {
                    fields {
                        sortindexnum
                    }
                    body
                    time
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`