import React from "react"
import { Row } from "../components/Wrapper"

const Timeline = ({ data }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    return (
        <div>
            {data.map((x, i) =>
                <Row key={i}>
                    <div className="relative">
                        {i === data.length - 1 &&
                            <div className="absolute h-1/2 w-1 ml-8 top-0 bg-primary"></div>
                        }
                        {i === 0 &&
                            <div className="absolute h-1/2 w-1 ml-8 bottom-0 bg-primary"></div>
                        }
                        {i > 0 && i < data.length - 1 &&
                            <div className="absolute h-full w-1 ml-8 bg-primary"></div>
                        }
                        <div className="ml-20 my-10">
                            <div
                                dangerouslySetInnerHTML={{ __html: converter.makeHtml(x.node.body) }}
                            />
                        </div>
                    </div>
                    <div className="absolute flex flex-col items-center justify-center ml-px">
                        <div className="relative rounded-full h-16 w-16 flex items-center justify-center bg-primary text-white">
                            {x.node.time}
                        </div>
                    </div>
                </Row>
            )}
        </div>
    )
}

export default Timeline